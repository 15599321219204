import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParentNavbarItem } from './side-navbar.interface';
import { NavbarChildItemComponent } from './navbar-child-item/navbar-child-item.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ecommerce-side-navbar',
  standalone: true,
  imports: [CommonModule, NavbarChildItemComponent, RouterModule],
  templateUrl: './side-navbar.component.html',
  styleUrl: './side-navbar.component.scss',
})
export class SideNavbarComponent {
  parentNavbarItems = input.required<ParentNavbarItem[]>();
  isFixed = input<boolean>();

  toggleNavbarChildren(parentItem: ParentNavbarItem) {
    parentItem.showChildren = !parentItem.showChildren;
  }
}
