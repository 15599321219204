@if (item()) {
  <a
    [routerLink]="item().childRoute"
    routerLinkActive="child-item-active"
    class="at-d-flex at-align-items-center at-px-3 at-py-2 child-item">
    @switch (item().childIcon) {
      @case ('shopping') {
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9933 15.1667H4.99996C3.85329 15.1667 2.99329 14.86 2.45996 14.2533C1.92662 13.6467 1.71995 12.7667 1.85995 11.6267L2.45996 6.62666C2.63329 5.15333 3.00663 3.83333 5.60663 3.83333H10.4066C13 3.83333 13.3733 5.15333 13.5533 6.62666L14.1533 11.6267C14.2866 12.7667 14.0866 13.6533 13.5533 14.2533C13 14.86 12.1466 15.1667 10.9933 15.1667ZM5.59995 4.83333C3.67995 4.83333 3.58662 5.59332 3.44662 6.73998L2.84663 11.74C2.74663 12.5867 2.86662 13.2067 3.20662 13.5867C3.54662 13.9667 4.14662 14.16 4.99996 14.16H10.9933C11.8466 14.16 12.4466 13.9667 12.7866 13.5867C13.1266 13.2067 13.2466 12.5867 13.1466 11.74L12.5466 6.73998C12.4066 5.58665 12.32 4.83333 10.3933 4.83333H5.59995Z"
            fill="#101b19" />
          <path
            d="M10.6666 5.83334C10.3933 5.83334 10.1666 5.60667 10.1666 5.33334V3C10.1666 2.28 9.71998 1.83334 8.99998 1.83334H6.99998C6.27998 1.83334 5.83331 2.28 5.83331 3V5.33334C5.83331 5.60667 5.60665 5.83334 5.33331 5.83334C5.05998 5.83334 4.83331 5.60667 4.83331 5.33334V3C4.83331 1.72667 5.72665 0.833336 6.99998 0.833336H8.99998C10.2733 0.833336 11.1666 1.72667 11.1666 3V5.33334C11.1666 5.60667 10.94 5.83334 10.6666 5.83334Z"
            fill="#101b19" />
          <path
            d="M13.6066 11.8535H5.33331C5.05998 11.8535 4.83331 11.6268 4.83331 11.3535C4.83331 11.0802 5.05998 10.8535 5.33331 10.8535H13.6066C13.88 10.8535 14.1066 11.0802 14.1066 11.3535C14.1066 11.6268 13.88 11.8535 13.6066 11.8535Z"
            fill="#101b19" />
        </svg>
      }

      @default {
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.01333 13.6867C6.45999 13.6867 4.90666 13.44 3.43333 12.9467C2.87333 12.7533 2.44666 12.36 2.25999 11.8467C2.06666 11.3333 2.13333 10.7667 2.43999 10.26L3.20666 8.98667C3.36666 8.72 3.51333 8.18667 3.51333 7.87334V5.94667C3.51333 3.46667 5.53333 1.44667 8.01333 1.44667C10.4933 1.44667 12.5133 3.46667 12.5133 5.94667V7.87334C12.5133 8.18001 12.66 8.72 12.82 8.99334L13.58 10.26C13.8667 10.74 13.92 11.32 13.7267 11.8467C13.5333 12.3733 13.1133 12.7733 12.5867 12.9467C11.12 13.44 9.56666 13.6867 8.01333 13.6867ZM8.01333 2.44667C6.08666 2.44667 4.51333 4.01334 4.51333 5.94667V7.87334C4.51333 8.36 4.31333 9.08 4.06666 9.5L3.29999 10.7733C3.15333 11.02 3.11333 11.28 3.19999 11.5C3.27999 11.7267 3.47999 11.9 3.75333 11.9933C6.53999 12.9267 9.49333 12.9267 12.28 11.9933C12.52 11.9133 12.7067 11.7333 12.7933 11.4933C12.88 11.2533 12.86 10.9933 12.7267 10.7733L11.96 9.5C11.7067 9.06667 11.5133 8.35334 11.5133 7.86667V5.94667C11.5133 4.01334 9.94666 2.44667 8.01333 2.44667Z"
            fill="#101b19" />
          <path
            d="M9.25345 2.62646C9.20678 2.62646 9.16012 2.61979 9.11345 2.60646C8.92012 2.55313 8.73345 2.51313 8.55345 2.48646C7.98678 2.41313 7.44012 2.45313 6.92678 2.60646C6.74012 2.66646 6.54012 2.60646 6.41345 2.46646C6.28678 2.32646 6.24678 2.12646 6.32012 1.94646C6.59345 1.24646 7.26012 0.786461 8.02012 0.786461C8.78012 0.786461 9.44678 1.23979 9.72012 1.94646C9.78678 2.12646 9.75345 2.32646 9.62678 2.46646C9.52678 2.57313 9.38678 2.62646 9.25345 2.62646Z"
            fill="#101b19" />
          <path
            d="M8.01331 15.2064C7.35331 15.2064 6.71331 14.9397 6.24664 14.473C5.77997 14.0064 5.51331 13.3664 5.51331 12.7064H6.51331C6.51331 13.0997 6.67331 13.4864 6.95331 13.7664C7.23331 14.0464 7.61997 14.2064 8.01331 14.2064C8.83997 14.2064 9.51331 13.533 9.51331 12.7064H10.5133C10.5133 14.0864 9.39331 15.2064 8.01331 15.2064Z"
            fill="#101b19" />
        </svg>
      }
    }

    <div class="at-text-hint at-paragraph-small at-fw-400">
      {{ item().childLabel }}
    </div>
  </a>
}
