import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { ChildNavbarItem } from '../side-navbar.interface';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'ecommerce-navbar-child-item',
  standalone: true,
  imports: [CommonModule, RouterModule, SvgIconComponent],
  templateUrl: './navbar-child-item.component.html',
  styleUrl: './navbar-child-item.component.scss',
})
export class NavbarChildItemComponent {
  item = input.required<ChildNavbarItem>();
}
